import React from 'react'
import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { rem } from 'polished'
import theme from '../../style/theme'
import { ALIKE } from '../../constants/type'

const Footer = () => {
  return (
    <Container>
      &copy;{new Date().getFullYear()} Ernest & Julio Gallo Winery, Modesto, CA.
      All rights reserved.
    </Container>
  )
}
export default Footer
const Container = styled(motion.div)`
  font-size: ${rem(10)};
  padding 2em 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-family: ${ALIKE};
  
  ${theme.media.md`
    font-size: ${rem(13)};
  `}
  ${theme.media.lg`
    font-size: ${rem(16)};
  `}
`
