import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const Blank = styled.span`
  display: inline-block;
  padding: 0 10px;
  min-width: 150px;
  border-bottom: solid 1px black;
  text-align: center;
`
export const Empty = styled.div`
  ${({ theme }) => css`
    width: 46px;
    ${theme.media.md`
      width: auto;
    `}
  `}
`

export const Number = styled.div(
  ({ theme }) => css`
    margin: 0 auto;

    border-radius: 50px;
    display: table;
    height: 1.7em;
    width: 1.7em;
    background-color: #ffc740;
    text-align: center;
    line-height: 1.7em;
    vertical-align: middle;
    padding-left: 5px;
    color: white;

    font-size: 1.125em;
    ${theme.media.md`
      font-size: 1.7rem;
    `}
  `,
)

export const Center = styled(motion.div)`
  display: flex;
  justify-content: center;
`
