import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { get, isNumber } from 'lodash'
import { rem, em } from 'polished'
import { fontSizes } from '../../style/theme'
import { ALIKE } from '../../constants/type'
import { motion } from 'framer-motion'

const fontKeys = Object.keys(fontSizes)

export const setFontSize = (size, ratio) => css`
  font-size: ${rem(size)};

  ${ratio &&
    css`
      line-height: ${ratio};
    `};
`

const setSize = (size, ratio) =>
  isNumber(size)
    ? setFontSize(size, ratio)
    : setFontSize(fontSizes[size], ratio)

const Text = styled.div`
  ${({
    center,
    color,
    ellipsis,
    family,
    weight,
    lineHeight,
    lineHeightMultiplier,
    margin,
    marginTop,
    nowrap,
    opacity,
    right,
    selectNone,
    size,
    spacing,
    theme,
    shadow,
    uppercase,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  }) => css`
    position: relative;

    font-family: ${family ? family : ALIKE}, sans-serif;
    font-weight: ${weight || 400};
    ${setSize(size, lineHeightMultiplier)};

    text-align: center;
    color: ${theme.color.questionText};
    margin: ${margin || 0};
    z-index: 1;
    
    ${marginTop &&
      `
      margin-top: ${marginTop};
    `}

    /* Override */

    ${shadow &&
      css`
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
      `}

    ${center &&
      css`
        text-align: center;
      `};

    ${color &&
      css`
        color: ${get(theme.color, color) || color};
      `};

    ${ellipsis &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `};

    ${nowrap &&
      css`
        white-space: nowrap;
      `};

    ${opacity &&
      css`
        opacity: ${opacity};
      `};

    ${lineHeight &&
      css`
        line-height: ${lineHeight};
      `};

    ${right &&
      css`
        text-align: right;
      `};

    ${selectNone &&
      css`
        user-select: none;
      `};

    ${spacing &&
      css`
        letter-spacing: ${!isNaN(spacing) ? rem(spacing) : spacing};
      `};

    ${uppercase &&
      css`
        text-transform: uppercase;
      `};

    /* Media queries */

    ${xs &&
      theme.media.xs`
        ${setSize(xs, lineHeightMultiplier)};
      `};

    ${sm &&
      theme.media.sm`
        ${setSize(sm, lineHeightMultiplier)};
      `};

    ${md &&
      theme.media.md`
         ${setSize(md, lineHeightMultiplier)};
      `};

    ${lg &&
      theme.media.lg`
         ${setSize(lg, lineHeightMultiplier)};
      `};

    ${xl &&
      theme.media.xl`
        ${setSize(xl, lineHeightMultiplier)};
      `};

    ${xxl &&
      theme.media.xxl`
        ${setSize(xxl, lineHeightMultiplier)};
      `};

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  `};
`

Text.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  ellipsis: PropTypes.bool,
  lineHeight: PropTypes.number,
  lineHeightMultiplier: PropTypes.number,
  linkColor: PropTypes.string,
  nowrap: PropTypes.bool,
  right: PropTypes.bool,
  selectNone: PropTypes.bool,
  slab: PropTypes.bool,
  helv: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // rem || 'normal'
  theme: PropTypes.object,
  white: PropTypes.bool,
  uppercase: PropTypes.bool,
  shadow: PropTypes.bool,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(fontKeys)]),
}

Text.defaultProps = {
  lineHeight: 1.7,
  lineHeightMultiplier: 1.48,
  size: fontSizes.md,
}

const headingProps = {
  lineHeightMultiplier: 1.2,
  bold: true,
  uppercase: true,
}

Text.h1 = Text.withComponent('h1')
Text.h1.defaultProps = {
  ...headingProps,
  lineHeightMultiplier: 54 / 46,
  size: 54 / 2,
  md: 37,
  xxl: 'xxxl',
  uppercase: true,
  spacing: em(-0.07),
  slab: true,
}

Text.h2 = Text.withComponent('h2')
Text.h2.defaultProps = {
  ...headingProps,
  lineHeightMultiplier: 1,
  lineHeightLg: 1.4,
  size: 44 / 2,
  md: 27,
  lg: 27,
  xxl: 44,
  uppercase: false,
  spacing: em(-0.07),
}

Text.h3 = Text.withComponent('h3')
Text.h3.defaultProps = {
  ...headingProps,
  lg: 'xl',
  lineHeightMultiplier: 1.35,
  spacing: 1.5,
}

Text.h4 = Text.withComponent('h4')
Text.h4.defaultProps = {
  ...headingProps,
  bold: true,
  lg: 'lg',
  spacing: 1.37,
  lineHeightMultiplier: 1.3125,
}

Text.h5 = Text.withComponent('h5')
Text.h5.defaultProps = {
  ...headingProps,
  bold: false,
  medium: true,
  lg: 'sm',
  spacing: 1.2,
  lineHeightMultiplier: 1.5,
  lineHeightLg: 1.6,
}

Text.h6 = Text.withComponent('h6')
Text.h6.defaultProps = {
  ...headingProps,
  bold: true,
  size: 'xs',
  lineHeightMultiplier: 1.32,
  spacing: 0.5,
}

Text.p = Text.withComponent('p')
Text.p.defaultProps = {
  ...Text.defaultProps,
}

Text.span = Text.withComponent('span')

Text.motion = Text.withComponent(motion.div)

export default Text
