import React from 'react'
import PropTypes from 'prop-types'
import SipInSpring from '../../assets/sip-in-spring.png'
import styled, { css } from 'styled-components/macro'

const LogoMobile = ({ light, ...props }) => {
  return (
    <HeadContainer>
      <LogoContainer {...props} />
    </HeadContainer>
  )
}

LogoMobile.propTypes = {
  light: PropTypes.bool,
}
export default LogoMobile

const HeadContainer = styled.div(
  ({ theme }) => css`
    position: relative;
  `,
)
const LogoContainer = styled.div(
  ({ theme }) => css`
    background: url(${SipInSpring});
    width: 100%;
    width: 100%;
    padding-bottom: 83.328%;
    background-size: auto 100%;
    background-position: top center;
    background-repeat: no-repeat;
  `,
)
