import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

const Content = styled.div(
  ({ theme }) => css`
    margin-top: -1em;
    padding: 0 ${rem(20)} 0;
    ${theme.media.xl`
        padding: 0;
      `};
    > a {
      min-width: ${rem(237)};

      ${theme.media.xl`
        min-width: ${rem(336)};
      `};
    }

    ${theme.media.lg`
    `};
  `,
)
export default Content
