import React, { useEffect } from 'react'
import ProcessingBoard from './views/ProcessingBoard'
import Section from '../../common/Section'
import theme from '../../../style/theme'
import { AnimatePresence, motion } from 'framer-motion'
import { useAnalytics } from 'use-analytics'
import { useAppContext } from '../../../context/AppContext'
import { QuestionTransitions } from '../../../style/transitions'
import LogoMobile from '../../common/LogoMobile'
import Logo from '../../common/Logo'
import styled from 'styled-components/macro'
import Container from '../../common/Container'
import Suggestions from './views/Suggestions'
import Footer from '../../common/Footer'

const waitAndExecute = (ms, fn) =>
  new Promise(r =>
    setTimeout(() => {
      fn()
      r()
    }, ms),
  )

const Results = () => {
  const { page } = useAnalytics()
  const {
    quiz: { answers, board, setResult },
    media: { isSmall },
  } = useAppContext()

  useEffect(() => {
    page()
    if (answers)
      waitAndExecute(3000, () => {
        setResult(answers)
      })
    //eslint-disable-next-line
  }, [answers])

  return (
    <Section bg={theme.color.jaundice}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.35 }}
        >
          <ProcessContainer>
            <StyledMotion
              initial={QuestionTransitions.initial}
              animate={QuestionTransitions.animate}
              exit={QuestionTransitions.exit}
              transition={QuestionTransitions.transition(0.7, 0)}
            >
              {isSmall ? <LogoMobile light /> : <Logo light />}
            </StyledMotion>
            {board ? <Suggestions board={board} /> : <ProcessingBoard />}
          </ProcessContainer>
        </motion.div>
      </AnimatePresence>
      <Footer />
    </Section>
  )
}

Results.propTypes = {}

export default Results

const ProcessContainer = styled(Container)``
const StyledMotion = styled(motion.div)`
  position: relative;
`
