import React, { forwardRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { QuestionTransitions } from '../../../style/transitions'
import Text from '../../common/Text'
import { ALIKE } from '../../../constants/type'
import { Blank, Empty, Number } from '../../common'

const QuestionLabel = forwardRef(({ text, questionId, responseText }, ref) => {
  // fill in the blanks
  let answers = (responseText && responseText.split('&')) || ['']
  let parts = text.split('__________')
  let question = text
  if (parts.length > 1) {
    question = parts.map((part, index) => {
      let res = <>&nbsp;</>
      if (answers.length && index > 0) {
        const ans = answers[0].trim()
        res = ans.length > 0 ? ans : <>&nbsp;</>
        answers.splice(0, 1)
      }
      if (index > 0)
        return (
          <Fragment key={index}>
            <Blank>{res}</Blank> {part}
          </Fragment>
        )
      else return part
    })
  }

  return (
    <QuestionContainer
      ref={ref}
      key={`q_${questionId}`}
      initial={QuestionTransitions.initial}
      animate={QuestionTransitions.animate}
      exit={QuestionTransitions.exit}
      transition={QuestionTransitions.transition(0.5, 0.6)}
    >
      <QuestionNumber>
        <Number>{questionId}.</Number>
      </QuestionNumber>
      <QuestionText>
        <Text.h2 lineHeight={1.2} margin="1em 0">
          {question}
        </Text.h2>
      </QuestionText>
      <Empty />
    </QuestionContainer>
  )
})

QuestionLabel.propTypes = {
  text: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
  responseText: PropTypes.string,
}

export default QuestionLabel

const QuestionText = styled.div`
  ${({ theme }) => css`
    text-align: center;

    h2 {
      margin-top: auto;
      top: calc(50% - 5px);
      transform: translateY(-50%);
      font-size: 1.125em;

      ${theme.media.md`
        margin-top: 1em;
        top: auto;
        transform: none;
        font-size: 1.75em;
      `}
    }
  `}
`
const QuestionContainer = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    font-family: ${ALIKE};

    ${theme.media.md`
      display: block;
    `}
  `}
`

const QuestionNumber = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    position: relative;
  `}
`
