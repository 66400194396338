import React, { useEffect } from 'react'
import Section from '../../common/Section'
import { Link, navigate, Redirect } from '@reach/router'
import { ROUTES } from '../../../constants'
import { useAppContext } from '../../../context/AppContext'
import { useAnalytics } from 'use-analytics'
import Container from '../../common/Container'
import LogoMobile from '../../common/LogoMobile'
import Logo from '../../common/Logo'
import Content from '../../common/Content'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { rem } from 'polished'
import { QuestionTransitions } from '../../../style/transitions'
import Text from '../../common/Text'
import { ALIKE } from '../../../constants/type'
import { Center, Empty, Number } from '../../common'
import Button from '../../common/Button'
import theme from '../../../style/theme'
import Footer from '../../common/Footer'

const Home = () => {
  const { page } = useAnalytics()
  const {
    quiz: { clearStoredAnswers, setStartTime },
    media: { isSmall },
    legal: { setAgeVerified, ageVerified },
  } = useAppContext()

  useEffect(() => {
    page()
    setStartTime(new Date())
    clearStoredAnswers()
    //eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (ageVerified === 'true') {
      navigate(ROUTES.QUESTIONS, { replace: true })
    }
  }, [ageVerified])

  return (
    <Section>
      <Container>
        {isSmall ? <LogoMobile /> : <Logo />}
        <Content>
          <GateContainer>
            <AnimatePresence>
              <GateIcon
                key="age_gate"
                initial={QuestionTransitions.initial}
                animate={QuestionTransitions.animate}
                exit={QuestionTransitions.exit}
                transition={QuestionTransitions.transition(0.5, 0.3)}
              >
                <Number>!</Number>
              </GateIcon>
            </AnimatePresence>
            <AnimatePresence>
              <GateText
                initial={QuestionTransitions.initial}
                animate={QuestionTransitions.animate}
                exit={QuestionTransitions.exit}
                transition={QuestionTransitions.transition(0.5, 0.45)}
              >
                <Text.h2 lineHeight={1.2} margin="1em 0">
                  Are you 21 or older?
                </Text.h2>
              </GateText>
            </AnimatePresence>
            <AnimatePresence>
              <GateText
                initial={QuestionTransitions.initial}
                animate={QuestionTransitions.animate}
                exit={QuestionTransitions.exit}
                transition={QuestionTransitions.transition(0.5, 0.6)}
              >
                <Text lineHeight={1.2} margin="1em 0">
                  By visiting this website, you affirm that you are of legal
                  drinking age.
                </Text>
              </GateText>
            </AnimatePresence>
            <AnimatePresence>
              <Center
                initial={QuestionTransitions.initial}
                animate={QuestionTransitions.animate}
                exit={QuestionTransitions.exit}
                transition={QuestionTransitions.transition(0.5, 1)}
              >
                <Button
                  uppercase
                  color={theme.color.nextQuestionButton}
                  onClick={() => setAgeVerified('true')}
                >
                  Enter
                </Button>
              </Center>
            </AnimatePresence>
          </GateContainer>
        </Content>
        <Center>
          <Footer />
        </Center>
      </Container>
    </Section>
  )
}

Home.propTypes = {}

export default Home

const GateContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-family: ${ALIKE};
    flex-direction: column;
    ${theme.media.md`
      display: block;
    `}
    ${Center} {
      padding-top: 2em;
    }
  `}
`

const GateIcon = styled(motion.div)`
  ${({ theme }) => css`
    margin: 0 auto 25px;
    position: relative;
    ${Number} {
      padding: 0;
    }
  `}
`

const GateText = styled(motion.div)`
  ${({ theme }) => css`
    text-align: center;

    h2 {
      font-size: 1.875em;
    }
    * {
      margin-top: auto;
      font-size: 1.125em;

      ${theme.media.md`
        margin-top: 1em;
        top: auto;
        transform: none;
        font-size: 1.35em;
      `}
    }
  `}
`
