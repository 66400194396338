import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'
import { ReactComponent as DownArrowIcon } from '../../assets/arrow-down.svg'

export const UpArrow = styled.img.attrs(({ src }) => ({
  src: require('../../assets/arrow-up.svg'),
}))`
  ${({ theme }) => css`
    display: inline;
    width: ${rem(12)};
    height: auto;
    margin-right: ${theme.space(1.5)};

    ${theme.media.xxl`
      width: ${rem(16)};
    `};
  `}
`

export const DownArrow = styled(DownArrowIcon)`
  ${({ theme }) => css`
    display: inline;
    height: ${rem(19)};
    width: auto;
    margin-right: ${theme.space(1.5)};
    transform: translatey(3px);

    ${theme.media.xxl`
      height: ${rem(29)};
      margin-right: ${theme.space(2)};
      transform: translatey(6px);
    `};
  `}
`

export const BackArrow = styled(DownArrowIcon)`
  ${({ theme, color }) => css`
    display: inline;
    height: ${rem(19)};
    width: auto;
    margin-right: ${theme.space(1.5)};
    position: relative;
    transform: translateY(6px) translateX(5px) rotate(90deg);

    polygon,
    path {
      fill: ${color}!important;
    }

    ${theme.media.xxl`
      height: ${rem(29)};
      margin-right: ${theme.space(2)};
      transform:  translateY(6px) translateX(10px) rotate(90deg);

    `};
  `}
`
