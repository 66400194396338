import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { rem, timingFunctions } from 'polished'
import Text from '../../common/Text'
import { motion } from 'framer-motion'
import theme from '../../../style/theme'
import { QUESTION_IMAGE_EXT } from '../../../constants'
import { ABRIL_FAT_FACE_REG } from '../../../constants/type'

const Answer = ({
  isSelected,
  backgroundImage,
  clickHandler,
  label,
  ...props
}) => {
  return (
    <MotionContainer {...props}>
      <InitContainer>
        <ImageContainer onClick={clickHandler} isSelected={isSelected}>
          <Background
            src={`/images/questions/${backgroundImage}.${QUESTION_IMAGE_EXT}`}
            alt={label}
          />
          <ValueText
            lineHeightMultiplier={1.15}
            helv
            color="white"
            xs={16}
            xl={18}
          >
            {label}
          </ValueText>
          <Border selected={isSelected} />
        </ImageContainer>
      </InitContainer>
    </MotionContainer>
  )
}

Answer.propTypes = {
  isSelected: PropTypes.bool,
  backgroundImage: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default Answer

const MotionContainer = styled(motion.div)`
  ${({ theme }) => css`
    margin: 5px;

    &:last-child {
      margin-right: 0;
    }

    width: calc(50% - 10px);

    ${theme.media.md`
      width: calc(33% - 10px);
    `}


    ${theme.media.lg`
      width: calc(25% - 10px);
    `}
    ${theme.media.xl`
      margin-right: ${theme.space(1)};
    `};
  `}
`
const ValueText = styled(Text)`
  position: absolute;
  width: 100%;
  font-family: ${ABRIL_FAT_FACE_REG};
  margin: 0 auto 0;
  padding: ${rem(56)} ${rem(5)} ${rem(18)};
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  bottom: 0;
`
const Border = styled.div`
  ${({ selected }) => css`
    opacity: ${selected ? 1 : 0};
    transition: opacity 0.5s;
    border: solid 5px ${theme.color.answerBackground};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
    ${theme.media.xl`
        border-width: 10px;
      `};
  `}
`

const Background = styled.img.attrs(({ src }) => ({ src }))`
  ${() => css`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `}
`
const InitContainer = styled.div`
  background-color: ${theme.color.answerBackground};
  width: 100%;
`
const ImageContainer = styled(motion.div)`
  ${({ theme }) => css`
    width: ${rem(415 / 2)};
    height: ${rem(415 / 2)};
    position: relative;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    transition: transform 0.3s ${timingFunctions('easeOutCubic')};
    display: block;

    height: 0;
    width: 100%;
    position: relative;
    padding-bottom: 100%;
    min-width: ${rem(150)};

    ${theme.media.xl`
    `};

    ${theme.mediaAndBelowFold.xl`
    `}
  `}
`
