export const QuestionTransitions = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: (duration = 0.3, delay = 1) => ({
    delay,
    duration,
  }),
}

export const QuestionVariants = {
  init: transition => ({
    opacity: 0,
    y: -20,
    transition,
  }),
  enter: transition => ({
    opacity: 1,
    y: 0,
    transition,
  }),
  exit: transition => ({
    opacity: 0,
    y: 20,
    transition,
  }),
}
