import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import Text from '../../../common/Text'
import { rem } from 'polished'
import { useAppContext } from '../../../../context/AppContext'
import { QuestionTransitions } from '../../../../style/transitions'
import { motion } from 'framer-motion'
// import { useAnalytics } from 'use-analytics'
import { ReactComponent as Loader } from '../../../../assets/images/waiting.svg'

// const waitAndExecute = (ms, fn) =>
//   new Promise(r =>
//     setTimeout(() => {
//       fn()
//       r()
//     }, ms),
//   )

const countByAndExecute = (ms, fn) => {
  return setInterval(() => {
    fn()
  }, ms)
}

const ProcessingBoard = () => {
  // const { track } = useAnalytics()
  const {
    // quiz: { answers, setResult },
    media: { isMedium, isSmall },
  } = useAppContext()

  const [ellipsisCount, setEllipsisCount] = useState(3)

  useEffect(() => {
    let count = 0
    const interval = countByAndExecute(250, () => {
      setEllipsisCount(count++ % 4)
    })
    // setResult(answers)

    return () => clearInterval(interval)
  }, [])

  // useEffect(() => {
  //   if (boardUri) {
  //     /*waitAndExecute(5000, () => {
  //       // clear stored answers and redirect to board
  //       clearStoredAnswers()
  //       if (boardType) {
  //         let timeEllapsed = ''
  //         let diff = 0
  //
  //         if (startTime) {
  //           diff = differenceInMilliseconds(new Date(), startTime)
  //           const minutes = Math.floor(diff / 60000)
  //           const seconds = ((diff % 60000) / 1000).toFixed(0)
  //           const elapsed = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  //
  //           timeEllapsed = `Completed in ${elapsed}. `
  //         }
  //
  //         track('Quiz completed', {
  //           category: 'click',
  //           label: `${timeEllapsed}Board served: ${boardType}`,
  //           value: diff,
  //         })
  //       }
  //       window.location = boardUri
  //     })*/
  //   }
  //   //eslint-disable-next-line
  // }, [boardUri])

  return (
    <BuckleUpContainer
      isSmall={isSmall}
      initial={QuestionTransitions.initial}
      animate={QuestionTransitions.animate}
      exit={QuestionTransitions.exit}
      transition={QuestionTransitions.transition(0.7, 0.25)}
    >
      <SearchText center xs={17} color="black">
        Searching for the perfect gift
        {!isMedium && <br />}
        <Ellipses>{[...Array(ellipsisCount).keys()].map(() => '.')}</Ellipses>
      </SearchText>
      <SpinContainer>
        <LoadingSpinner />
      </SpinContainer>
    </BuckleUpContainer>
  )
}

ProcessingBoard.propTypes = {}

export default ProcessingBoard

const SearchText = styled(Text)`
  margin: 2em auto;
  margin-top: 2em !important;
`
const Ellipses = styled.span`
  min-width: 20px;
  text-align: left;
  display: inline-block;
`
const BuckleUpContainer = styled(motion.div)`
  ${({ theme, isSmall }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;

    img {
      width: 70%;
      height: 70%;
      object-fit: contain;
    }

    ${Text} {
      margin-top: ${theme.space(1)};
      width: ${rem(330)};
      text-align: center;

      ${theme.media.md`
        width: ${rem(500)};
        left: ${rem(15)};
        text-align: center;


      `}
    }

    ${theme.media.lg`
      top: ${rem(300)};
      width: 100%;
      
      img {
        width: ${rem(370)}};
        height: ${rem(112)}};
      }
    `};

    ${theme.media.xl`
      img {
        width: ${rem(530)}};
        height: ${rem(160)}};
      }
    
    `};
  `}
`

const LoadingSpinner = styled(Loader)`
  width: 100%;
  height: auto;
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
const SpinContainer = styled.div`
  ${({ theme }) => css`
    width: 10%;
    max-width: ${rem(144)};
    margin-bottom: 1em;

    ${theme.media.lg`
      margin-bottom: 2em;
    `}
  `}
`
