import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../common/Container'
import Section from '../../common/Section'
import styled, { css } from 'styled-components/macro'
import Button from '../../common/Button'
import Logo from '../../common/Logo'
import Gallery from '../../common/Gallery'
import QuestionLabel from './QuestionLabel'
import { ROUTES, TYPE_ANSWER_CLICK } from '../../../constants'
import { navigate } from '@reach/router'
import { useAppContext } from '../../../context/AppContext'
import { rem } from 'polished'
import { AnimatePresence, motion } from 'framer-motion'
import { QuestionTransitions } from '../../../style/transitions'
import { UpArrow } from '../../common/Arrow'
import theme from '../../../style/theme'
import LogoMobile from '../../common/LogoMobile'
import { useAnalytics } from 'use-analytics'
import Footer from '../../common/Footer'
import Content from '../../common/Content'
import { Center } from '../../common'

const Questions = () => {
  const { track, page } = useAnalytics()
  const {
    data,
    media: { isSmall },
    quiz: {
      answers,
      setAnswer,
      questionId,
      setQuestionId,
      startTime,
      setStartTime,
    },
  } = useAppContext()

  // const [hasTracked1, setHasTracked1] = useState(false)
  const [currentSelection, setCurrentSelection] = useState(null)
  const [galleryTransitionComplete, setGalleryTransitionComplete] = useState(
    true,
  )

  const questionIndex = questionId - 1
  const currentQuestion = data[questionIndex]
  const atLastQuestion = questionId >= data.length

  const isDisabled =
    answers[questionIndex].length < 1 || !galleryTransitionComplete

  useEffect(() => {
    track('page_load')
  }, [track])

  useEffect(() => {
    page()
    if (questionIndex === 0 && !startTime) setStartTime(new Date())
    //eslint-disable-next-line
  }, [questionId])

  // useEffect(() => {
  //   //eslint-disable-next-line
  //   if (window.pintrk && questionIndex === 1 && !hasTracked1) {
  //     window.pintrk('track', 'lead', { lead_type: 'quiz_start' })
  //     setHasTracked1(true)
  //   }
  //   //eslint-disable-next-line
  // }, [questionIndex])

  useEffect(() => {
    const selection = answers[questionId - 1]
    let response
    if (data.length >= questionId - 1 && selection.length > 0) {
      const { answers: opts } = data[questionId - 1]
      if (opts.length >= selection[0]) {
        response = opts[selection[0]].text
      }
    }
    setCurrentSelection(response)
    //eslint-disable-next-line
  }, [answers, questionId])

  const handleNextQuestion = useCallback(() => {
    // GA track
    if (!isDisabled && answers[questionIndex].length > 0) {
      const event = `Q${questionIndex + 1}_submit`

      if (atLastQuestion) {
        track('quiz_finish', {})
      }
      track(event, {})
    }
    if (!isDisabled && !atLastQuestion) {
      setQuestionId(questionId + 1)
    } else if (atLastQuestion) {
      navigate(ROUTES.RESULTS)
    }
    //eslint-disable-next-line
  }, [atLastQuestion, isDisabled, questionId, setQuestionId])

  const handlePrevQuestion = useCallback(() => {
    if (questionId <= data.length) {
      setQuestionId(questionId - 1)
    }
    //eslint-disable-next-line
  }, [questionId, setQuestionId])

  const handleAnswerSelect = useCallback(
    answerIndex => () =>
      setAnswer({
        type: TYPE_ANSWER_CLICK,
        questionIndex,
        answerIndex,
        maxAnswerIndex: currentQuestion.maxAnswers,
        data,
      }),
    //eslint-disable-next-line
    [currentQuestion.maxAnswers, questionIndex, setAnswer],
  )

  return (
    <Section>
      <Container>
        {isSmall ? <LogoMobile /> : <StyledLogo />}
        <Content>
          <AnimatePresence exitBeforeEnter>
            {questionIndex > 0 ? (
              <PrevLink
                key="prevLink"
                onClick={handlePrevQuestion}
                initial={QuestionTransitions.initial}
                animate={QuestionTransitions.animate}
                exit={QuestionTransitions.exit}
                transition={QuestionTransitions.transition(0.5, 0.2)}
              >
                <UpArrow />
              </PrevLink>
            ) : null}
          </AnimatePresence>

          <AnimatePresence exitBeforeEnter>
            {data
              .filter((q, i) => i === questionIndex)
              .map((question, i) => {
                return (
                  <QuestionLabel
                    text={question.text}
                    questionId={questionId}
                    key={`question_${i}`}
                    responseText={currentSelection}
                  />
                )
              })}
          </AnimatePresence>
          <AnswerContainer>
            <Gallery
              answers={answers}
              currentQuestion={currentQuestion}
              questionIndex={questionIndex}
              handleAnswerSelect={handleAnswerSelect}
              handleTransitionComplete={setGalleryTransitionComplete}
            />
          </AnswerContainer>
          <Center
            initial={QuestionTransitions.initial}
            animate={QuestionTransitions.animate}
            exit={QuestionTransitions.exit}
            transition={QuestionTransitions.transition(0.2, 1.5)}
          >
            <Button
              onClick={handleNextQuestion}
              disabled={isDisabled}
              uppercase
              color={theme.color.nextQuestionButton}
            >
              {atLastQuestion ? 'Finish' : 'Next Question'}
            </Button>
          </Center>
          <ProgressWrapper>
            <Progress
              width={Math.ceil((questionId / data.length) * 100) + '%'}
            />
          </ProgressWrapper>
        </Content>
      </Container>
      <Footer />
    </Section>
  )
}

Questions.propTypes = {}

export default Questions

const StyledLogo = styled(Logo)`
  ${({ theme }) => css``}
`

const ProgressWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.color.darkGray};
    margin-top: 2em;
  `,
)
const Progress = styled.div(
  ({ theme, width = '100%' }) => css`
    width: ${width};
    height: ${rem(6)};
    background: ${theme.color.answerBackground};
    transition: width 0.5s ease-in-out;
    ${theme.media.lg`
      height: ${rem(9)};
    `};
  `,
)

const PrevLink = styled(motion.div)`
  ${({ theme }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      transform: rotate(-90deg);
    }

    div {
      transform: translateY(3px);
    }

    opacity: 1;
    transform: none;
    position: absolute;
    margin: 0;
    z-index: 2;

    right: 0;
    margin-top: 0.35em;

    ${theme.media.md`
      margin-top: ${theme.space(2)};
      left: 8vw;
      right: auto;
    `}
  `};
`

const AnswerContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin: 0.75em auto ${rem(18)};

    ${theme.media.md`
      margin: ${theme.space(1)} 0 ${rem(10)};
    `};

    ${theme.media.xl`
      margin-bottom: ${rem(18)};
    `};

    .flickity-enabled:focus {
      outline: none !important;
      border: none;
    }
  `}
`
