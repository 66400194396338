import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
// import { rem, timingFunctions } from 'polished'
import Text from '../../../common/Text'
import { AnimatePresence, motion } from 'framer-motion'
import { QuestionTransitions } from '../../../../style/transitions'
import Button from '../../../common/Button'
import theme from '../../../../style/theme'
import BG from '../../../../assets/images/results/bg.png'
import { rem } from 'polished'
import { ABRIL_FAT_FACE_REG, ALIKE } from '../../../../constants/type'
import { useAnalytics } from 'use-analytics'

const Suggestions = ({ board }) => {
  const { track } = useAnalytics()
  const [menus, setMenus] = useState()

  useEffect(() => {
    if (board && board.length) {
      const ms = board.map(() => false)
      setMenus(ms)
    }
  }, [board])

  const toggleMenu = (index, id) => {
    if (!menus[index]) track(`result_${id}_buynow`)
    setMenus(menus.map((m, i) => (index === i ? !m : false)))
  }

  const getUrl = (answer, type) => {
    const { url } = answer || {}
    return url[type]
  }

  return (
    <Wrapper>
      <AnimatePresence exitBeforeEnter>
        <QuestionText>
          <Text.h2 marginTop="1.2em">
            Here are some gift & sip options based on your sipping story!
          </Text.h2>
        </QuestionText>
      </AnimatePresence>
      <Container>
        <AnimatePresence exitBeforeEnter>
          {board.map((answer, answerIndex) => {
            return (
              <Gift key={answerIndex}>
                <Card>
                  <Bottle src={`/images/bottles/${answer.id}.png`} />
                  <Name>{answer.name}</Name>
                  <Menu
                    selected={
                      menus && menus.length > answerIndex && menus[answerIndex]
                    }
                  >
                    <VerticalCenter>
                      <MenuLink
                        rel="noopener noreferrer"
                        target="_blank"
                        href={getUrl(answer, 'buy')}
                        onClick={() =>
                          track(`result_${answer.id}_buyonline`, {})
                        }
                      >
                        Buy Online
                      </MenuLink>
                      <MenuLink
                        rel="noopener noreferrer"
                        target="_blank"
                        href={getUrl(answer, 'find')}
                        onClick={() =>
                          track(`result_${answer.id}_findinstore`, {})
                        }
                      >
                        Find In-Store
                      </MenuLink>
                      <MenuLink
                        rel="noopener noreferrer"
                        target="_blank"
                        href={getUrl(answer, 'delivery')}
                        onClick={() =>
                          track(`result_${answer.id}_getdelivered`, {})
                        }
                      >
                        Get Delivered
                      </MenuLink>
                    </VerticalCenter>
                  </Menu>
                </Card>
                <MenuButton
                  onClick={() => toggleMenu(answerIndex, answer.id)}
                  selected={
                    menus && menus.length > answerIndex && menus[answerIndex]
                  }
                >
                  Buy Now
                </MenuButton>
              </Gift>
            )
          })}
        </AnimatePresence>
      </Container>
      <Center
        initial={QuestionTransitions.initial}
        animate={QuestionTransitions.animate}
        exit={QuestionTransitions.exit}
        transition={QuestionTransitions.transition(0.2, 1.5)}
      >
        <a
          rel="noopener noreferrer"
          href="https://www.gallo.com/"
          target="_blank"
          onClick={() => track('explore_more', {})}
        >
          <Button uppercase color={theme.color.nextQuestionButton}>
            Explore More
          </Button>
        </a>
      </Center>
    </Wrapper>
  )
}

Suggestions.propTypes = {
  board: PropTypes.array,
}

export default Suggestions

const QuestionText = styled.div`
  ${({ theme }) => css`
    padding-left: 0.75rem;
    text-align: center;

    h2 {
      margin-top: 0.5em;
      line-height: 1.2em;
      font-size: 1.125em;

      ${theme.media.md`
      font-size: 1.75em;    
    `}
    }
  `}
`
const Center = styled(motion.div)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin: 1em 0 0;

    ${theme.media.md`
    margin: 3em 0 2em;
  `}
  `}
`

const Name = styled.div`
  position: absolute;
  bottom: 1em;
  width: 100%;
  text-align: center;
  color: white;
  font-family: ${ABRIL_FAT_FACE_REG};
  font-size: ${rem(14)};
`

const Gift = styled(motion.div)`
  ${({ theme }) => css`
    width: calc(50% - 10px);
    margin: 5px;

    &:last-child {
      margin-right: 0;
    }

    ${theme.media.md`
      width: calc(25% - 10px);
    `};
  `}
`
const Container = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 1em auto 0;
  width: 100%;
`
const Card = styled.div`
  background-image: url(${BG});
  background-position: center center;
  background-size: cover;
  width: 100%;
  padding-bottom: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`
const Btn = css`
  ${({ theme, selected }) => css`
    user-select: none;
    display: table;
    margin: 0 auto;
    font-family: ${ABRIL_FAT_FACE_REG};
    color: #fff;
    padding: 0.5em 1em;
    margin-top: 1em;
    font-size: 12px;
    background: #005c30;
    cursor: pointer;
    text-align: center;
    &:hover,
    &:active,
    &:link,
    &:visited {
      color: #fff;
    }
    min-width: 6em;
    border: 3px solid transparent;
    border-radius: 13px;
    transition: border 0.3s;

    ${selected &&
      `
    border: 3px solid #FFC73F;
  `}
    ${theme.media.md`
    min-width: 10em;
  `}
  `}
`
const MenuLink = styled.a`
  ${Btn}
  font-family: ${ALIKE};
  min-width: 9em;
`
const MenuButton = styled.div`
  ${Btn}
`
const Menu = styled.div`
  ${({ selected }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 199, 63, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translateY(${selected ? '0' : '100%'});
    transition: all 0.5s;
  `}
`
const VerticalCenter = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`
const Bottle = styled.img`
  height: 100%;
  width: auto;
  margin: 0 auto;
  height: 100%;
  /* width: 100%; */
  margin: 0 auto;
  position: absolute;
  display: flex;
`
const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 90%;
    margin: 0 auto;
    ${theme.media.md`
      max-width: auto;
      margin: auto;
    `}
  `}
`
