import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import Answer from '../containers/questions/Answer'
import { QuestionTransitions } from '../../style/transitions'
import styled, { css } from 'styled-components'

const Gallery = ({
  answers,
  currentQuestion,
  questionIndex,
  handleTransitionComplete,
  handleAnswerSelect,
}) => {
  const [flickityRef, setFlickityRef] = useState(null)

  useEffect(() => {
    handleTransitionComplete(false)
  }, [handleTransitionComplete, questionIndex])

  useEffect(() => {
    handleTransitionComplete(true)
  }, [handleTransitionComplete])

  const handleGalleryReload = useCallback(() => {
    if (flickityRef) {
      handleTransitionComplete(true)
    }
    //eslint-disable-next-line
  }, [flickityRef, handleTransitionComplete])

  return (
    <Container ref={ref => setFlickityRef(ref)}>
      <AnimatePresence exitBeforeEnter onExitComplete={handleGalleryReload}>
        {currentQuestion.answers.map((answer, answerIndex) => {
          const isSelected = answers[questionIndex].some(i => i === answerIndex)
          return (
            <Answer
              animate={QuestionTransitions.animate}
              backgroundImage={answer.id}
              clickHandler={handleAnswerSelect(answerIndex)}
              exit={QuestionTransitions.exit}
              initial={QuestionTransitions.initial}
              isSelected={isSelected}
              key={answer.text}
              label={answer.text}
              transition={QuestionTransitions.transition(
                0.35,
                answerIndex * 0.1,
              )}
            />
          )
        })}
      </AnimatePresence>
    </Container>
  )
}

Gallery.propTypes = {
  answers: PropTypes.array,
  currentQuestion: PropTypes.object,
  questionIndex: PropTypes.number,
  handleTransitionComplete: PropTypes.func,
  handleAnswerSelect: PropTypes.func,
}

export default Gallery

const Container = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    ${theme.media.lg`
      display: flex;
      flex-wrap: nowrap;
  `}
  `}
`
