import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

const Container = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    height: 100%;

    ${theme.media.xl`
      width: 75%;
    `}
    h1 {
      max-width: ${rem(950)};
    }
  `}
`

export default Container
