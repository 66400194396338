import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import Text from './Text'
import { Link } from '@reach/router'
import { rem } from 'polished'
import theme, { media } from '../../style/theme'
import { TIMES } from '../../constants/type'

const BLUE = 'blue'
const TRANSPARENT = 'transparent'
const WHITE = 'white'
const themeKeys = [BLUE, TRANSPARENT, WHITE]

const smallProps = {
  size: 17,
  lg: 18,
  xl: 19,
}

const Button = ({
  disabled,
  children,
  color,
  href,
  onClick,
  to,
  rel,
  small,
  target,
  minWidth,
  theme,
  uppercase,
  ...props
}) => {
  const Wrapper = to ? StyledLink : StyledWrapper
  const smallText = small ? smallProps : {}

  const content = (
    <Container minWidth={minWidth}>
      <Text
        family={TIMES}
        center
        weight="bold"
        lineHeight={30}
        sans
        size={26}
        sm={19}
        lg={20}
        xl={26}
        lineHeightMultiplier={1}
        color={theme === WHITE ? 'darkRed' : WHITE}
        uppercase={uppercase}
        {...smallText}
      >
        {children}
      </Text>
    </Container>
  )

  return !!href ? (
    <StyledExternalLink href={href} target={target} rel={rel} type={theme}>
      {content}
    </StyledExternalLink>
  ) : (
    <Wrapper
      to={to}
      onClick={onClick}
      disabled={disabled}
      type={theme}
      color={color}
    >
      {content}
    </Wrapper>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  small: PropTypes.bool,
  rel: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  theme: PropTypes.oneOf(themeKeys),
  uppercase: PropTypes.bool,
}

Button.defaultProps = {
  padHorizontal: rem(23),
  theme: BLUE,
  uppercase: false,
  color: theme.color.darkGreen,
}

export default Button

const wrapperStyle = ({ disabled, theme, type, color }) => css`
  padding-top: 0;
  display: inline-flex;
  justify-content: center;
  transition: opacity 0.3s;
  background-color: ${color};
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: ${TIMES};

  width: calc(100% - 10px);

  ${theme.media.md`
    width: auto;
  `}

  ${type === WHITE &&
    css`
      background-color: #fff;
    `};

  ${type === TRANSPARENT &&
    css`
      background-color: transparent;
    `};

  ${disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`

const StyledWrapper = styled.button`
  ${wrapperStyle};
`

const StyledLink = styled(Link)`
  ${wrapperStyle};
`

const StyledExternalLink = styled.a`
  ${wrapperStyle};
`

const Container = styled.div`
  ${({ type, theme, minWidth }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.space(1.3)} 6em;

    ${Text} {
      vertical-align: top;
      line-height: inherit;

      ${theme.media.sm`
        white-space: nowrap;
      `};
    }

    img {
      width: 20px;
      height: auto;
      transform: translateY(-3px);
    }

    ${typeof minWidth === 'object' &&
      css`
        ${Object.keys(minWidth).map(
          key => css`
            ${media[key]`
            min-width: ${minWidth[key]}
          `}
          `,
        )}
      `}
  `}
`
