import React from 'react'
import PropTypes from 'prop-types'
import LogoSipInSpring from '../../assets/sip-in-spring.png'

import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

const Logo = ({ light, ...props }) => {
  return (
    <LogoContainer {...props}>
      <Layout>
        <SipInSpring src={LogoSipInSpring} alt="Sip in Spring" />
      </Layout>
    </LogoContainer>
  )
}

Logo.propTypes = {
  light: PropTypes.bool,
}
export default Logo

const Layout = styled.div(
  ({ theme }) => css`
    position: relative;
    min-width: ${rem(156)};
    max-width: 950px;
    margin: 0 auto;
  `,
)
const SipInSpring = styled.img(
  () => css`
    width: 100%;
    position: relative;
  `,
)
const LogoContainer = styled.div(
  ({ theme }) => css`
    width: 100%;

    max-width: 800px;
    margin: 0 auto;

    ${theme.media.md`
      min-width: ${rem(250)};
    `};

    ${theme.media.xl`
      /*margin-bottom: ${theme.space(6.5)};*/
    `};

    z-index: 2;
  `,
)
