import React, { createContext, useContext, useMemo, useState } from 'react'
import useQuiz from '../hooks/useQuiz'
import { breakpointsPx } from '../style/theme'
import { questions, mapping, results } from '../data/data.json'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import useLocalStorage from '../hooks/useLocalStorage'

export const AppContext = createContext(null)

// const getData = async (url, set) => {
//   try {
//     const response = await fetch(url)
//     if (response.ok) {
//       const json = await response.json()
//       const { questions: list, boards } = json
//
//       set({
//         list,
//         boards,
//         ready: true,
//       })
//     } else
//       set({
//         ready: false,
//       })
//   } catch (error) {
//     set({
//       ready: false,
//     })
//   }
// }

const AppProvider = ({ children }) => {
  const [startTime, setStartTime] = useState()
  const [questionId, setQuestionId] = useState(1)
  const [ageVerified, setAgeVerified] = useLocalStorage('ageVerified', 'false')
  const [loaded, setLoaded] = useState(false)
  const data = {
    list: questions,
    boards: mapping,
    gifts: results,
    ready: true, // todo: if we're not fetching data, we can remove this globally
  }
  const {
    answers,
    setAnswer,
    clearStoredAnswers,
    board,
    boardUri,
    boardType,
    setResult,
  } = useQuiz(data)

  const isSmall = useMedia(`(max-width: ${breakpointsPx.md - 1}px)`)
  const isMedium = useMedia(`(min-width: ${breakpointsPx.md}px)`)
  const isLarge = useMedia(`(min-width: ${breakpointsPx.lg}px)`)
  const isXLarge = useMedia(`(min-width: ${breakpointsPx.xl}px)`)
  const isXXLarge = useMedia(`(min-width: ${breakpointsPx.xxl}px)`)
  const value = useMemo(() => {
    return {
      loaded,
      setLoaded,
      data: data.list,
      dataReady: data.ready,

      media: {
        isMedium,
        isLarge,
        isSmall,
        isXLarge,
        isXXLarge,
      },
      quiz: {
        answers,
        board,
        boardUri,
        boardType,
        setAnswer,
        clearStoredAnswers,
        questionId,
        setQuestionId,
        setResult,
        startTime,
        setStartTime,
      },
    }
  }, [
    loaded,
    data.list,
    data.ready,
    isMedium,
    isLarge,
    isSmall,
    isXLarge,
    isXXLarge,
    answers,
    board,
    boardUri,
    boardType,
    setAnswer,
    clearStoredAnswers,
    questionId,
    setResult,
    startTime,
  ])

  return (
    <AppContext.Provider
      value={{
        ...value,
        legal: {
          ageVerified,
          setAgeVerified,
        },
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.any,
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider
