import { useCallback, useEffect, useReducer, useState } from 'react'
import { TYPE_ANSWER_CLICK, TYPE_RESET } from '../constants'
import useAnswerStorage from './useAnswerStorage'
import { questions as questionList } from '../data/data.json'
const selectAnswer = (
  state,
  { answerIndex, questionIndex, type, data = [] },
) => {
  switch (type) {
    case TYPE_ANSWER_CLICK:
      // clear response (or remove next line if multiple responses allowed)
      state[questionIndex] = []

      const indexExisting = state[questionIndex].findIndex(
        i => i === answerIndex,
      )

      if (indexExisting > -1) state[questionIndex].splice(indexExisting, 1)
      else state[questionIndex].push(answerIndex)

      return [...state]
    case TYPE_RESET:
      return data.map(() => [])
    default:
      throw new Error(`Unknown type: ${type}`)
  }
}

// const indexOfMaxValue = arr => arr.indexOf(Math.max(...arr))

/*const calculateResult = (questions, selections, mappings) => {
  const filter = item => item.length > 0 // test that the array has contents
  const completedQuiz = questionList.length === selections.filter(filter).length
  if (!completedQuiz) return false

  let sets = {}
  for (const [key, value] of Object.entries(mappings)) {
    sets[key] = value.map(() => 0)
  }

  let ret = {}

  selections.forEach((selectedValue, selectedIndex) => {
    const answers = questions[selectedIndex].answers[selectedValue]
    for (const [key] of Object.entries(mappings)) {
      if (answers) {
        sets[key] = sets[key].map((item, i) => item + answers[key][i])
      }
    }
  })
  for (const [key] of Object.entries(mappings)) {
    ret[key] = mappings[key][indexOfMaxValue(sets[key])]
  }

  return ret
}*/

const useQuiz = data => {
  const { storedAnswers, serializeAnswers } = useAnswerStorage(data.list)
  // const [boardUri, setBoardUri] = useState()
  // const [boardType, setBoardType] = useState()
  const [board, setBoard] = useState()

  const initAnswerArrays = questionList.map(() => [])
  const [answers, setAnswer] = useReducer(
    selectAnswer,
    storedAnswers.length ? storedAnswers : initAnswerArrays,
  )

  const clearStoredAnswers = useCallback(() => {
    setAnswer({ type: TYPE_RESET, data: data.list })
    serializeAnswers()
  }, [data, serializeAnswers])

  const setResult = useCallback(
    answerIndexes => {
      const { list, boards, gifts } = data

      const getResponseValue = (questionIndex, responseIndex) => {
        let res
        if (list.length > questionIndex) {
          const question = list[questionIndex]
          const { answers } = question
          if (answers.length > responseIndex) {
            res = answers[responseIndex].value
          }
        }
        return res
      }

      const answerSet = answerIndexes.map((arr, idx) =>
        arr.length === 1 ? getResponseValue(idx, arr[0]) : null,
      )

      const matches = boards.filter(board => {
        const { responses } = board
        if (responses.length === answerSet.length) {
          let success = true
          responses.forEach((b, i) => {
            if (b !== '*' && answerSet[i] !== b) {
              success = false
            }
          })
          return success
        } else {
          return false
        }
      })

      let results = []

      matches.forEach(m => {
        results = [...results, ...m.results]
      })
      results = results.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )

      const giftChoices = results.map(r => {
        const item = gifts.filter(b => b.id === r)
        if (item && item.length) return item[0]
        else return null
      })

      setBoard(giftChoices)
    },
    [data],
  )

  useEffect(() => {
    serializeAnswers(answers)
  }, [answers, serializeAnswers])

  return {
    answers,
    board,
    clearStoredAnswers,
    setAnswer,
    setResult,
  }
}

export default useQuiz
