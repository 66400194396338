import { useCallback, useState } from 'react'

function useLocalStorage(key, initialValue) {
  const [storageValue, setStorageValue] = useState(initialValue)

  const setValue = useCallback(
    value => {
      console.log('val', value)
      if (!value) {
        window.localStorage.removeItem(key)
        setStorageValue(null)
      } else {
        window.localStorage.setItem(key, value)
        setStorageValue(value)
      }
    },
    [key],
  )
  const item = window.localStorage.getItem(key)
  if (!item && initialValue) setValue(initialValue)

  return [item || initialValue, setValue]
}

export default useLocalStorage
